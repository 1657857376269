<template>
    <v-container fluid>
      <v-row>
        <v-col>
          <h1>Paid Application Report</h1>
        </v-col>
      </v-row>
      <v-row class="no-print">
        <v-col class="col-3">
            <v-date-picker
            v-model="date"
            type="month"
            ></v-date-picker>
        </v-col>
    </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
            <thead>
              <th class="text-left">Job</th>
              <th class="text-left">Client</th>
              <th class="text-left">Site</th>
              <th class="text-left">Amount</th>
              <th class="text-left">Paid</th>
              <th class="text-left">Date</th>
              <th class="text-left">Notes</th>
            </thead>
            <tbody>
              <tr v-for="(application, index) in applications" :key="`r_${index}`">
                <td>{{ application.jobNumber }}</td>
                <td>{{ application.client }}</td>
                <td>{{ application.site }}</td>
                <td>{{ application.amount | currency }}</td>
                <td>{{ application.paid | currency }}</td>
                <td>{{ application.date }}</td>
                <td>
                    <span v-if="application.notes">
                        {{ application.notes }}
                    </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row class="no-print">
        <v-col>
          <download-csv
            name="paid-applications.csv"
            :data="applications">
            <v-btn>Export</v-btn>
          </download-csv>
        </v-col>
        <v-col>
          <v-btn @click="print">Print</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import axios from '../../axios';
  
  export default {
    name: 'PaidApplicationReport',
    computed: {
      token() {
        return this.$store.state.token;
      },
    },
    data() {
      return {
        applications: [],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        exportFields: [
          'customer',
          'sageRef',
          'number',
          'date',
          'amount',
          'amountPaid',
          'amountCredited',
          'amountRetained',
          'amountDue',
        ],
      };
    },
    watch: {
        date() {
            this.application = [];
            this.getApplications();
        },
    },
    methods: {
      getApplications() {
        const postData = {
            date: this.date,
        };
        axios.post(`/invoices/getPaidApplications.json?token=${this.token}`, postData)
            .then((response) => {
            this.applications = response.data.applications;
            }); 
      },
      print() {
        window.print();
      },
    },
    mounted() {
      this.getApplications();
    },
  };
  </script>
    